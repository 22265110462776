// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

// function CollapsibleExample() {
//   return (
//     <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
//       <Container>
//         <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
//         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//         <Navbar.Collapse id="responsive-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link href="#features">Features</Nav.Link>
//             <Nav.Link href="#pricing">Pricing</Nav.Link>
//             <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
//               <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.2">
//                 Another action
//               </NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//               <NavDropdown.Divider />
//               <NavDropdown.Item href="#action/3.4">
//                 Separated link
//               </NavDropdown.Item>
//             </NavDropdown>
//           </Nav>
//           <Nav>
//             <Nav.Link href="#deets">More deets</Nav.Link>
//             <Nav.Link eventKey={2} href="#memes">
//               Dank memes
//             </Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default CollapsibleExample;

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import down from "../Assets/down-2-svgrepo-com.svg";
import logo from "../Assets/Sceniuz_Logo.svg";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    // Close the submenu when the route changes
    setIsDropdownOpen(false);
    setIsMobileMenuOpen(false);
  }, [location.pathname]); // Trigger effect on route change

  return (
    <nav className="bg-white sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-black font-bold">
              <img src={logo} alt="" srcset="" />
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="flex items-center">
              <Link to="/about-us" className="text-black  px-3 py-2 rounded-md">
                About
              </Link>
              <div className="relative">
                <button
                  onClick={toggleDropdown}
                  className="text-black  px-3 py-2 rounded-md focus:outline-none flex items-center gap-2"
                >
                  Services
                  <img src={down} alt="" srcset="" className="down-icon w-5 " />
                </button>
                {isDropdownOpen && (
                  <div className="absolute mt-2 w-max bg-white rounded-md shadow-lg">
                    <Link
                      to="/data-analytics"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      onClick={closeMobileMenu}
                    >
                      Data Analytics
                    </Link>
                    <Link
                      to="/data-engineering"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      onClick={closeMobileMenu}
                    >
                      Data Engineering
                    </Link>
                    <Link
                      to="/data-advisory"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      onClick={closeMobileMenu}
                    >
                      Data Advisory
                    </Link>
                  </div>
                )}
              </div>
              <Link
                to="/contact-us"
                className="text-black  px-3 py-2 rounded-md"
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="block md:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-black  px-3 py-2 rounded-md focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link
              to="/about-us"
              className="text-black  block px-3 py-2 rounded-md"
              onClick={closeMobileMenu}
            >
              About Us
            </Link>
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="text-black  block px-3 py-2 rounded-md w-full text-left focus:outline-none flex items-center gap-2"
              >
                Services
                <img src={down} alt="" srcset="" className="down-icon w-5 " />
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-2 w-full bg-white rounded-md shadow-lg">
                  <Link
                    to="/data-analytics"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    onClick={closeMobileMenu}
                  >
                    Data Analytics
                  </Link>
                  <Link
                    to="/data-engineering"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    onClick={closeMobileMenu}
                  >
                    Data Engineering
                  </Link>
                  <Link
                    to="/data-advisory"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    onClick={closeMobileMenu}
                  >
                    Data Advisory
                  </Link>
                </div>
              )}
            </div>
            <Link
              to="/contact-us"
              className="text-black  block px-3 py-2 rounded-md"
              onClick={closeMobileMenu}
            >
              Contact Us
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
